.admin-table-container {
  max-height: 600px; 
  overflow-y: auto;
  width: 80%;
}

.file-table {
  width: 100%;
  border-collapse: collapse;
}

.file-table th,
.file-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ac9090;
}

.file-table th {
  background-color: #f7cf72fb;
}

.font-medium.text-blue-600.dark:text-blue-500.hover\:underline {
  border: 1px solid transparent; 
  transition: border-color 0.3s; 
}

.font-medium.text-blue-600.dark:text-blue-500.hover\:underline:hover {
  border-color: blue; 
}

@media screen and (max-width: 615px) {
  .px-6 {
    padding-left: 0.5rem;
    padding-right: 1.5rem;
  }
}