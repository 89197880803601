.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 1000;
}

.bg-green-highlight {
  background-color: #7ef266; /* dff0d8 */

}
.box-container {
  display: flex;
  height: 100vh;
  justify-content: center; 
  align-items: center; 
  padding: 20px;
  position: relative; 
}

.close-button-container {
  display: flex;
  justify-content: flex-end;
}

.close-button {
  background: none;
  cursor: pointer;
  padding: 8px; 
  border-radius: 50%; 
  transition: background-color 0.3s;
  display: inline-flex; 
  align-items: center; 
}

.close-button:hover {
  background-color: #EF4444; 
 
}

.close-icon {
  font-size: 24px;
  color: #000;
  transition: transform 0.3s; 
}

.close-button:hover .close-icon {
  transform: rotate(90deg); 
  color: #fff;
}
.sub-box-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  max-width: 700px;
  width: 100%;
  background: #fff;
  padding: 25px 30px;
  border-radius: 5px;
}

.create-table-container {
  max-height: 300px; 
  overflow-y: auto;
}

.file-table {
  width: 100%;
  border-collapse: collapse;
}

.file-table th,
.file-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ac9090;
}

.file-table th {
  background-color: #f7cf72fb;
}

.update-button {
  padding: 6px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.update-button:hover {
  background-color: #0056b3;
}

.create-form {
  margin-top: 20px;
}

.create-form input,
.create-form textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.font-medium.text-blue-600.dark:text-blue-500.hover\:underline {
  border: 1px solid transparent; 
  transition: border-color 0.3s; 
}

.font-medium.text-blue-600.dark:text-blue-500.hover\:underline:hover {
  border-color: blue; 
}



@media screen and (max-width: 615px) {
  .px-6 {
    padding-left: 0.5rem;
    padding-right: 1.5rem;
  }
}
