/* Dark mode styles */
.dark-mode {
    background-color: #363537;
    color: #fff;
  }
  
  /* Additional dark mode styles you provided */
  
  /* Toggle button styles */
  .dark-mode .react-toggle-dark-mode {
    background-color: #3498db;
  }
  
  /* Animation keyframes for growing */
  @keyframes growCircle {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Apply animation to the button when toggled */
  .dark-mode .react-toggle-dark-mode.toggled {
    animation: growCircle 0.5s ease-in-out;
  }
  
  /* Change background color when toggled */
  .dark-mode .react-toggle-dark-mode.toggled {
    background-color: #e74c3c;
  }
  