.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dim the background */
    z-index: 1000;
  }
  
  .box-container {
    display: flex;
    height: 100vh;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    padding: 20px;
    position: relative; /* Added to make .close-button-container positioning relative to this container */
  }
  
  .close-button-container {
    display: flex;
    justify-content: flex-end;
  }

  
  .close-button {
    background: none;
    cursor: pointer;
    padding: 8px; 
    border-radius: 50%; 
    transition: background-color 0.3s;
    display: inline-flex; 
    align-items: center; 
  }
  
  .close-button:hover {
    background-color: #EF4444; 

  }
  
  .close-icon {
    font-size: 24px;
    color: #000;
    transition: transform 0.3s; 
  }
  
  .close-button:hover .close-icon {
    transform: rotate(90deg); 
    color: #fff;
  }
  
  .sub-box-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    max-width: 700px;
    width: 100%;
    background: #fff;
    padding: 25px 30px;
    border-radius: 5px;
  }
  
  .table-container {
    max-height: 300px; /* Adjust the height as needed */
    overflow-y: auto;
  }
  
  .file-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .file-table th,
  .file-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ac9090;
  }
  
  .file-table th {
    background-color: #f7cf72fb;
  }
  
  .update-button {
    padding: 6px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .update-button:hover {
    background-color: #0056b3;
  }
  
  .update-form {
    margin-top: 20px;
  }
  
  .update-form input,
  .update-form textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  
  .confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .modal-content p {
    margin-bottom: 20px;
  }
  
  .modal-content button {
    margin-right: 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-content button:hover {
    background-color: #ddd;
  }