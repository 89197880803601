@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
#root {
  font-family: "Poppins", sans-serif;
}


.account-table-container {
  max-height: 600px; 
  overflow-y: auto;
  width: 80%;
}

.file-table {
  width: 100%;
  border-collapse: collapse;
}

.file-table th,
.file-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ac9090;
}

.file-table th {
  background-color: #f7cf72fb;
}

.font-medium.text-blue-600.dark:text-blue-500.hover\:underline {
  border: 1px solid transparent; 
  transition: border-color 0.3s; 
}

.font-medium.text-blue-600.dark:text-blue-500.hover\:underline:hover {
  border-color: blue; 
}

@media screen and (max-width: 615px) {
  .px-6 {
    padding-left: 0.5rem;
    padding-right: 1.5rem;
  }
}


.account-dashboard-container {
  padding: 20px;
}

.account-dashboard-table-scroll {
  max-height: 400px; /* Adjust as needed */
  overflow-y: auto;
}

.table-container-admin {
  max-height: 500px;
  overflow-y: auto;
}

.delete-button {
  color: #fff;
  font-size: 0.9rem;
  background: #ef4444;
}

.delete-confirm-model {
  position: fixed;
  top: 30%;
  left: 30%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  z-index: 1000;
  max-width: 550px;
  background: #fff;
  width: 100%;
  padding: 25px 30px;
  border-radius: 5px;
}

.border-b {
  border: 1px solid #000;
}

.table-container-admin table {
  width: 100%;
}

.table-container-admin th,
.table-container-admin td {
  padding: 12px;
}

.table-container-admin th {
  font-size: 1.25rem;
  font-weight: 600;
}

.table-container-admin td {
  font-size: 1rem;
}

.table-container-admin .text-center {
  text-align: center;
}

.table-container-admin .align-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-container-admin .align-middle button {
  height: 100%;
}

/* Reduce space between title, developer, and installs */
.table-container-admin .font-medium {
  margin-bottom: 0.25rem; /* Adjust margin as needed */
}

/* Reduce space between website and app-ads.txt */
.table-container-admin .flex-col p {
  margin-bottom: 0.25rem; /* Adjust margin as needed */
}

.delete-confirm-model .flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-button {
  border: 2px solid #ef4444;
}

@media (max-width: 768px) {
  .delete-confirm-model {
    max-width: 80%;
    left: 10%;
  }
}

@media (max-width: 576px) {
  .delete-confirm-model {
    max-width: 90%;
    padding: 15px 20px;
    left: 5%;
  }
}

@media screen and (max-width: 615px) {
  .px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
