@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.app-background {
  background: #e55d87;
  background: -webkit-linear-gradient(-45deg, #5fc3e4, #e55d87);
  background: linear-gradient(-45deg, #5fc3e4, #3ca55c, #e55d87, #e5e5be);
  background-size: 400% 400%;
  height: 100vh;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  animation: gradient 10s linear infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.custom-shape-divider-bottom-1678428448 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1678428448 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-bottom-1678428448 .shape-fill {
  fill: #ffffff;
}

@media screen and (max-width: 640px) {
  .main-card {
    background: url("../src/assets/images/bg-sidebar-mobile.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 90%;
    height: 70%;
  }
}
