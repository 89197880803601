.step-title {
    display: block; 
  }
  
  @media screen and (max-width: 640px) {
    .display-card{
      display: none;
    }
    .step-title {
      display: none; 
    }
  }